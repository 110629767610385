/**
 * Timetastic
 * Annual leave policy page
 */
import React, { useEffect } from "react"
// import { Link } from "gatsby"

// Helpers
import { animate, trackCta } from "src/helpers"

// Components
import Footer from "src/components/footer"
import FooterCta from "src/components/footerCta"
import FreeTrial from "src/components/freeTrial"
import Header from "src/components/header"
import Layout from "src/components/layout"
import SEO from "src/components/seo"

// Styles
import "src/styles/templates/downloads.scss"

// SEO
const title = "Annual Leave Policy - Free to Download"
const description =
  "FREE Download. Annual Leave Policy Template. Download in any format. The customisable Policy Template covers your company’s Annual Leave Allowance."

// JSX
const Default = () => {
  // Animate any elements with a class of 'is-unanimated'
  useEffect(() => {
    animate()
  }, [])

  return (
    <>
      <SEO title={title} description={description} />
      <Layout>
        <Header />
        <FooterCta />
        <main className="c-download-template-main">
          {/* Hero */}
          <section className="c-section c-download-hero u-text-centre u-text-left-mobile">
            <div className="u-inner c-download-hero__inner is-unanimated">
              <h1 className="h h1 c-download-hero__title">
                Annual leave policy
              </h1>
              <p>
                An annual leave policy is there to make it clear what your team
                is entitled to and how to arrange time off in a fair and
                consistent manner. Having a policy that your team can refer to
                brings visibility to the system and helps avoid conflict.
              </p>

              <div className="">
                <h2 className="h h5">Download our completely free template</h2>
                <ul className="c-button-list">
                  <li className="">
                    <a
                      href="/documents/Timetastic_annual_leave_policy_template.pdf"
                      className="c-button c-button--primary c-button--xl"
                      download
                      rel="noreferrer noopener"
                      target="_blank"
                      onClick={() => {
                        trackCta("AbsencePolicyDownload_PDF")
                      }}
                    >
                      <svg
                        focusable="false"
                        height="24"
                        viewBox="0 0 20 24"
                        width="20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <use xlinkHref="#svg-icon-pdf" />
                      </svg>
                      Download as PDF
                    </a>
                  </li>
                  <li className="">
                    <a
                      href="/documents/Timetastic_annual_leave_policy_template.docx"
                      className="c-button c-button--secondary c-button--xl"
                      download
                      rel="noreferrer noopener"
                      target="_blank"
                      onClick={() => {
                        trackCta("AbsencePolicyDownload_DOCX")
                      }}
                    >
                      <svg
                        focusable="false"
                        height="24"
                        viewBox="0 0 20 24"
                        width="20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <use xlinkHref="#svg-icon-word" />
                      </svg>
                      Download as Word
                    </a>
                  </li>
                  <li className="">
                    <a
                      href="https://docs.google.com/document/d/1FWOWVhMrt3VIFF6VsBMRfQxKz4DsVEW8kCw0mGE5hWc/edit?usp=sharing"
                      className="c-button c-button--tertiary c-button--xl"
                      download
                      rel="noreferrer noopener"
                      target="_blank"
                    >
                      <svg
                        focusable="false"
                        height="24"
                        viewBox="0 0 20 24"
                        width="20"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => {
                          trackCta("AbsencePolicyDownload_GDOC")
                        }}
                      >
                        <use xlinkHref="#svg-icon-google-doc" />
                      </svg>
                      Open Google Doc
                    </a>
                  </li>
                </ul>
                <div className="c-download__plc">
                  <img
                    loading="eager"
                    alt="Plain Language Commission"
                    src="/images/plain-language-commission.svg"
                    width="63px"
                    height="80px"
                  />
                  <p>Accredited by the Plain Language Commission</p>
                </div>
              </div>
            </div>
          </section>
          {/* Information */}
          <section className="c-section c-download-questions">
            <div className="u-inner u-inner--l">
              <div className="c-download-faq">
                <div className="c-pricing-question is-unanimated">
                  <h2 className="h h4">How to use the template</h2>
                  <p>
                    Once you have downloaded the annual leave policy template
                    please have a detailed read through and fill in any blanks.
                  </p>
                  <p>
                    It’s vital to check the template carefully and make any
                    changes necessary to align it with your existing policies
                    and procedures.
                  </p>
                  <p>
                    You must be certain that you’re complying with relevant
                    employment law. If in doubt we suggest getting help from an
                    employment law specialist.
                  </p>
                </div>
                <div className="c-pricing-question is-unanimated">
                  <h2 className="h h4">What’s included?</h2>
                  <p>
                    This annual leave policy covers annual holiday entitlement,
                    how to arrange it with the agreement of your manager, carry
                    over to the following year and how it interacts with other
                    absence types.
                  </p>
                  <p>
                    It also has a statement on what happens at the end of
                    employment, this will need aligning to your employment
                    contracts.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <FreeTrial
            body="Accountants, marketing agencies, lawyers, engineers — over 7,000 businesses like yours manage their team’s annual leave with Timetastic."
            buttonCopy="Start your free trial"
            buttonHref="/pricing/"
            title="You should try Timetastic"
            trackCtaLabel="AnnualLeavePolicyTemplateSignUp"
          />
          <section className="c-section c-download-wording">
            <div className="u-inner">
              <h2 className="h h2">The policy wording</h2>
              <h3 className="h h3">Annual Leave Policy</h3>
              <h4 className="h h4">What is this policy for?</h4>
              <p>
                Taking time off to rest and recuperate is important. You should
                spend time with your family and friends, have a holiday or just
                put your feet up. We all need a healthy work-life balance.
              </p>
              <p>That’s why everyone gets an annual holiday allowance.</p>
              <p>
                This Annual Leave Policy explains how to agree holidays with
                your manager, how we deal with requests in a fair and consistent
                way, and answers some important questions about your holiday
                allowance.
              </p>
              <h4 className="h h4">What is the holiday year?</h4>
              <p>
                Our holiday year runs from{" "}
                <em>
                  [specify your holiday year e.g. 1 January to 31 December]
                </em>
                .
              </p>
              <h4 className="h h4">What is your holiday allowance?</h4>
              <p>
                You can find your holiday allowance in your contract of
                employment.
              </p>
              <p>
                During your first and last year of work, your allowance is
                reduced proportionately. This reflects the proportion of time
                you worked during that year.
              </p>
              <h4 className="h h4">How you request time off</h4>
              <p>
                Please request and organise your holidays well in advance, so
                that we can plan for your absence and arrange for your work to
                be covered.
              </p>
              <p>
                If you’re off work without getting your manager’s approval,
                we’ll treat it as unauthorised absence.
              </p>
              <p>
                <em>[If you use Timetastic]</em>
              </p>
              <p>
                We use Timetastic to organise all time off work, and we’ll give
                you the login details you need. In Timetastic you can request
                time off, see if it clashes with anyone else in your department,
                and keep track of how much holiday allowance you have left.
              </p>
              <p>
                <em>[If you don’t use Timetastic]</em>
              </p>
              <p> Describe your system for requesting time off here.</p>
              <h4 className="h h4">
                How we decide whether or not to approve your request
              </h4>
              <p>We’ll make every effort to agree your requests.</p>
              <p>We deal with requests on a first-come, first-served basis.</p>
              <p>
                We consider how much work we have on and any deadlines we need
                to meet.
              </p>
              <h4 className="h h4">What if you want a long holiday?</h4>
              <p>
                Longer holidays can be more difficult for us to work around. If
                you want more than <em>[insert time period]</em> weeks off,
                please discuss it with your manager.
              </p>
              <h4 className="h h4">
                Can you carry your holiday allowance into next year?
              </h4>
              <p>
                We prefer you to use all your holiday allowance during the year,
                but we know it’s not always possible.
              </p>
              <p>
                You can carry forward <em>[number of days/ hours]</em> into the
                next year.
              </p>
              <h4 className="h h4">What if you’re sick while on holiday?</h4>
              <p>
                If you’re sick while on annual leave, any days covered by a
                medical certificate can be switched to sickness. Talk to your
                manager if this happens.
              </p>
              <p>
                If you do switch some time to sickness, our&nbsp;
                <a
                  className="u-em-link"
                  href="/absence-policy-template/"
                  onClick={() => {
                    trackCta("HomeCustomers")
                  }}
                >
                  <span>Staff Absence Policy applies</span>
                </a>
                .
              </p>
              <h4 className="h h4">What happens when you leave?</h4>
              <p>
                There are a few options we can use if some holiday allowance is
                left when you come to leave our employment. We’ll decide which
                option is best at the time.
              </p>
              <ol>
                <li>Take the time off during your notice period.</li>
                <li>Pay you the equivalent value in your final salary.</li>
                <li>A mix of the two.</li>
              </ol>
              <p>
                <em>
                  [Only use the following statement if your contracts of
                  employment allow you to make deductions from pay.]
                </em>
              </p>
              <p>
                At the end of your employment, if you’ve taken more annual leave
                than has accrued, we’ll deduct the value of the excess from your
                final salary.
              </p>
            </div>
          </section>
        </main>
        <Footer />
      </Layout>
    </>
  )
}
export default Default
